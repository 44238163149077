import cn from 'classnames';
import classNames from 'classnames';

import { AuthRolesService } from '@/src/modules/auth/services/authRoles.service';
import { routes, routesParts, urlWithIds } from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { Link } from '@/src/modules/common/utils';
import { eventsActions } from '@/src/modules/events/actions/events.actions';
import { EventEntity, EventModuleRightsTypes } from '@/src/modules/events/models/events.models';
import { Locale } from '@/src/modules/i18n';
import { MenuItem } from 'primereact/menuitem';

export type CreateEventMenuItemsOptions = {
	tPages: (id: string) => string;
	locale: Locale;
	event: EventEntity;
	workspaceId?: string;
	linkClassName?: string;
	spanClassName?: string;
	checkedLinkClassName?: string;
	iconClassName?: string;
	checkedId?: string;
};

export const createEventMenuItems = ({
	event,
	workspaceId,
	checkedId,
	locale,
	spanClassName,
	linkClassName,
	tPages,
	checkedLinkClassName,
	iconClassName,
}: CreateEventMenuItemsOptions): MenuItem[] => {
	const rolesService = new AuthRolesService();

	const id = event.id ?? -1;

	const eventPagesMenuItemTemplate = (route: Routes, item: MenuItem) => (
		<Link
			href={
				item.disabled
					? ''
					: urlWithIds(routes[route].path, [
							{ match: routesParts.workspace, value: workspaceId ?? '' },
							{ match: routesParts.event, value: id.toString() ?? '' },
						])
			}
			locale={locale as Locale}
			className={cn(
				'p-2 p-menuitem-link',
				item.disabled && 'pointer-events-none opacity-40',
				linkClassName,
				checkedId === item.id && checkedLinkClassName,
			)}
		>
			<span
				className={classNames('p-menuitem-icon ' + item.icon, spanClassName, iconClassName)}
			/>
			<span className={classNames('p-menuitem-text text-sm', spanClassName)}>
				{item.label}
			</span>
		</Link>
	);

	const items: MenuItem[] = [
		{
			id: Routes.EventStatus,
			label: tPages('event.status.title'),
			icon: 'pi pi-home text-xs',
			template: (item) => eventPagesMenuItemTemplate(Routes.EventStatus, item),
		},
	];

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewActivitiesModule) {
		items.push({
			id: Routes.EventActivities,
			label: tPages('event.activities.title'),
			icon: 'pi pi-list text-xs',
			template: (item) => eventPagesMenuItemTemplate(Routes.EventActivities, item),
		});
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewScheduleModule) {
		items.push({
			id: Routes.EventSchedule,
			label: tPages('event.schedule.title'),
			icon: 'pi pi-th-large text-xs',
			disabled: false,
			template: (item) => eventPagesMenuItemTemplate(Routes.EventSchedule, item),
		} as MenuItem);
	}
	if (
		rolesService.isGlobalAdmin ||
		eventsActions.someModuleHasRight(
			event.modules,
			EventModuleRightsTypes.canViewPublicationsModule,
		)
	) {
		items.push({
			id: Routes.EventPublications,
			label: tPages('event.publications.title'),
			icon: 'pi pi-server text-xs',
			template: (item) => eventPagesMenuItemTemplate(Routes.EventPublications, item),
		} as MenuItem);
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewTeamModule) {
		items.push({
			id: Routes.EventTeam,
			label: tPages('event.team.title'),
			icon: 'pi pi-users text-xs',
			disabled: false,
			template: (item) => eventPagesMenuItemTemplate(Routes.EventTeam, item),
		} as MenuItem);
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewLiveScheduleModule) {
		items.push({
			id: Routes.EventScheduleLive,
			label: tPages('event.schedule-live.title'),
			icon: 'pi pi-database text-xs',
			disabled: true,
			template: (item) => eventPagesMenuItemTemplate(Routes.EventScheduleLive, item),
		} as MenuItem);
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewReportModule) {
		items.push({
			id: Routes.EventReport,
			label: tPages('event.report.title'),
			icon: 'pi pi-book text-xs',
			template: (item) => eventPagesMenuItemTemplate(Routes.EventReport, item),
		} as MenuItem);
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewRatingsModerationModule) {
		items.push({
			id: Routes.EventRatings,
			label: tPages('event.ratings.title'),
			icon: 'pi pi-comments text-xs',
			template: (item) => eventPagesMenuItemTemplate(Routes.EventRatings, item),
		} as MenuItem);
	}

	// TODO
	if (rolesService.isGlobalAdmin || event?.rights?.canViewSupportCalendarModule) {
		items.push({
			id: Routes.EventSupportCalendar,
			label: tPages('event.support-calendar.title'),
			icon: 'pi pi-book text-xs',
			disabled: true,
			template: (item) => eventPagesMenuItemTemplate(Routes.EventSupportCalendar, item),
		} as MenuItem);
	}

	items.push(
		...[
			{
				id: Routes.EventConfig,
				label: tPages('event.config.title'),
				icon: 'pi pi-check-square text-xs',
				disabled: true,
				template: (item) => eventPagesMenuItemTemplate(Routes.EventConfig, item),
			} as MenuItem,

			{
				id: Routes.EventIntegrations,
				label: tPages('event.integrations.title'),
				icon: 'pi pi-play text-xs',
				disabled: true,
				template: (item) => eventPagesMenuItemTemplate(Routes.EventIntegrations, item),
			} as MenuItem,

			{
				id: Routes.EventDesign,
				label: tPages('event.design.title'),
				icon: 'pi pi-thumbs-up-fill text-xs',
				disabled: true,
				template: (item) => eventPagesMenuItemTemplate(Routes.EventDesign, item),
			} as MenuItem,
			{
				id: Routes.EventUsers,
				label: tPages('event.users.title'),
				icon: 'pi pi-percentage text-xs',
				disabled: !rolesService.isGlobalAdmin,
				template: (item) => eventPagesMenuItemTemplate(Routes.EventUsers, item),
			} as MenuItem,
		],
	);

	return items;
};
